

























































































































/* eslint-disable camelcase */
import Vue from 'vue';
import Component from 'vue-class-component';
import UserService from '@/services/User.service';
import { User } from '@/types/user-types';
import { CustomerIDocument, IDocumentType } from '@/types/document-types';
import { AxiosResponse } from 'axios';

@Component
export default class UserList extends Vue {
  Modal = false;

  UserData = new Array<User>();

  BlockLoading: boolean[] = [];

  SelectedUser = new User();

  DisplayData = new Array<Record<string, string>>();

  DocumentTypes = new Array<IDocumentType>();

  CreationDate = '';

  Loading = true;

  Avatar = 0;

  Documents = new Array<CustomerIDocument>();

  async mounted() {
    this.SelectedUser.fromRaw({
      status: '',
      name: '',
      email: '',
      password: '',
      location_id: 0,
      permission_level: 0,
    });

    const UService = new UserService();
    let response: AxiosResponse;
    try {
      this.Loading = true;

      // TODO: Make these three requests in parallel, not in series.

      response = await UService.getAll();
      response.data.forEach((value: User) => {
        const userToPush = new User();
        userToPush.fromRaw(value);
        this.UserData.push(userToPush);
        this.BlockLoading.push(false);
      });

      this.Loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async archiveUser(email: string, index: number) {
    const us = new UserService();
    this.BlockLoading[index] = true;
    await us.archiveSingle({ email });
    this.$router.go(0);
  }
}
