

































































































































































































































































































import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { Customer } from '@/types/customer-types';
import { AxiosPromise, AxiosResponse } from 'axios';
import { BarAction } from '@/components/application/UtilityBar/utility-bar-types';
import UtilityBar from '@/components/application/UtilityBar/UtilityBar.vue';
import StringSelect from '@/components/application/Filters/StringSelect.vue';
import DateIntSelect from '@/components/application/Filters/DateIntSelect.vue';
import DisplayBox from '@/components/application/Filters/DisplayBox.vue';
import StringSearch from '@/components/application/Filters/StringSearch.vue';
import LocationService from '@/services/Location.service';
import { Location } from '@/types/location-types';
import InfoDisplayBox from '@/components/application/Filters/InfoDisplayBox.vue';
import UserService from '@/services/User.service';
import TransactionService from '@/services/Transaction.service';
import { TRADER } from '@/types/transaction-enums';
import AddMoneyDialog from '@/components/application/Transactions/AddMoneyDialog.vue';
import SendMoneyDialog from '@/components/application/Transactions/SendMoneyDialog.vue';
import Loading from '@/components/application/Loading.vue';

@Component({
  components: {
    UtilityBar,
    StringSelect,
    DateIntSelect,
    StringSearch,
    AddMoneyDialog,
    SendMoneyDialog,
    Loading,
    DisplayBox,
    InfoDisplayBox,
  },
})
export default class Index extends Vue {
  public barChunks: BarAction[] = [
    {
      icon: 'mdi-pail',
      text: 'BUCKETS',
      eventName: 'go-to-buckets',
    },
    {
      icon: 'mdi-download-multiple',
      text: 'ADD MONEY',
      eventName: 'add-money-start',
    },
    {
      icon: 'mdi-send',
      text: 'SEND MONEY',
      eventName: 'send-money-start',
    },
  ];

  public OfficerVariantsFull: Array<{id: number, name: string}> = [];

  public selectedOfficer: {id: number, name: string} | null = null;

  public addMoneyDialog = false;

  public openAddMoney(): void {
    this.addMoneyDialog = true;
  }

  public closeAddMoney(): void {
    this.addMoneyDialog = false;
  }

  public sendMoneyDialog = false;

  public openSendMoney(): void {
    this.sendMoneyDialog = true;
  }

  public closeSendMoney(): void {
    this.sendMoneyDialog = false;
  }

  public async goToBuckets() {
    await this.$router.push({ name: 'Services & Buckets' });
  }

  SearchField = '';

  TotalPages = 0;

  LoadingResults = false;

  CurrentPage = 0;

  TableData: any[] = [];

  SortOrder = 'descending';

  @Watch('selectedOfficer')
  onOfficerChange(newVal: any) {
    if (newVal === null) {
      this.walletValue = 0;
    }
    // this.paginate('');
  }

  public infoData: Array<{
    field: string;
    value: string | number;
  }> = [];

  public walletValue = 0;

  public income = 0;

  public expense = 0;

  public profit = 0;

  async paginate(e: string) {
    const TService = new TransactionService();

    let response: AxiosResponse;

    let paginateFunction: (params: any) => Promise<AxiosResponse> = TService.paginateAll;
    if (this.selectedOfficer !== null) {
      paginateFunction = TService.getWallet;
    }

    this.TableData = [];
    try {
      this.LoadingResults = true;

      // let params;
      // if (this.selectedOfficer !== null) {
      //   params = {
      //     order: this.SortOrder,
      //     wallet_id: this.selectedOfficer.id,
      //     wallet_type: TRADER.OFFICER,
      //   };
      // } else {
      const params = {
        wallet_id: this.selectedOfficer!.id,
        wallet_type: TRADER.OFFICER,
        filters: this.Filters,
        order: this.SortOrder,
      };
      // }

      response = await paginateFunction(params);
      this.LoadingResults = false;
      const TransactionData = response.data.entries.data;
      this.TotalPages = response.data.entries.last_page;
      this.income = response.data.income;
      this.expense = response.data.expense;
      this.profit = response.data.profit;
      this.infoData = [
        { field: 'Petty Cash', value: response.data.petty },
        { field: 'Cash Income', value: response.data.cashIncome },
        { field: 'Card Income', value: response.data.cardIncome },
        { field: 'Total Income', value: response.data.income },
        { field: 'Real Expenses', value: response.data.realExpenses },
        { field: 'Estimated (Service) Expenses', value: response.data.estimatedExpenses },
        { field: 'Wallet Value', value: response.data.amount },
      ];
      this.CurrentPage = 1;
      TransactionData.forEach((transaction: any) => {
        this.TableData.push(transaction);
      });
      if (this.selectedOfficer !== null) {
        this.walletValue = response.data.amount;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async refresh() {
    const TS = new TransactionService();
    await this.paginate('');
    const wallet = await TS.getWallet({
      wallet_type: TRADER.OFFICER,
      wallet_id: -1,
    });
    this.personalWalletValue = wallet.data.amount;
  }

  // eslint-disable-next-line class-methods-use-this
  public parseDate(input: string): string {
    const d = new Date(input);
    return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
  }

  Filters: Array<{
    field: string;
    operator: string;
    operand: string;
  }> = [];

  public setFilters(filters: Array<{
    field: string;
    operator: string;
    operand: string;
  }>): void {
    filters.forEach((filter) => {
      this.Filters = this.Filters.filter((e) => e.field !== filter.field);
    });
    this.Filters = this.Filters.concat(filters);
    this.paginate('');
  }

  public unsetFilters(filters: string[]): void {
    filters.forEach((filter) => {
      this.Filters = this.Filters.filter((e) => e.field !== filter);
    });
    this.paginate('');
  }

  LocationVariants: string[] = [];

  OfficerVariants: string[] = [];

  personalWalletValue = 0;

  personalId = 1;

  async mounted() {
    const LS = new LocationService();
    const US = new UserService();
    const TS = new TransactionService();

    const responses = await Promise.all([
      LS.getAll(),
      US.getAll(),
      TS.getWallet({
        wallet_id: -1,
        wallet_type: TRADER.OFFICER,
      }),
    ]);

    this.LocationVariants = responses[0].data.map((location: Location) => location);
    this.OfficerVariants = responses[1].data.map((officer: any) => officer.name);
    this.OfficerVariantsFull = responses[1].data.map((officer: any) => ({
      name: officer.name,
      id: officer.id,
    }));
    this.personalWalletValue = responses[2].data.amount;
    this.personalId = responses[2].data.user.id;

    this.selectedOfficer = {
      id: this.personalId,
      name: 'N\\A',
    };

    await this.paginate('');
  }

  async moveToPage(e: string, f?: string[]) {
    const TService = new TransactionService();

    let response: AxiosResponse;

    let paginateFunction: (params: any) => Promise<AxiosResponse> = TService.paginateAll;
    if (this.selectedOfficer !== null) {
      paginateFunction = TService.getWallet;
    }

    this.TableData = [];
    try {
      this.LoadingResults = true;

      let params;
      if (this.selectedOfficer !== null) {
        params = {
          page: parseInt(e, 10),
          order: this.SortOrder,
          wallet_id: this.selectedOfficer.id,
          wallet_type: TRADER.OFFICER,
        };
      } else {
        params = {
          page: parseInt(e, 10),
          filters: this.Filters,
          order: this.SortOrder,
        };
      }

      response = await paginateFunction(params);
      const TransactionData = response.data.entries.data;
      this.CurrentPage = parseInt(e, 10);
      TransactionData.forEach((customer: any) => {
        this.TableData.push(customer);
      });
      this.LoadingResults = false;
    } catch (error) {
      console.log(error);
    }
  }
}
