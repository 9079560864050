





































































import 'reflect-metadata';
// eslint-disable-next-line object-curly-newline
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import CustomerService from '@/services/Customer.service';
import PSTService from '@/services/PST.service';
import SessionService from '@/services/Session.service';

@Component
export default class AddToSessionDialog extends Vue {
  @Prop() readonly model!: boolean;

  @Prop() readonly date!: Date;

  @Prop() readonly customerId!: number;

  Packages: unknown[] = [];

  ChosenPackage = -1;

  Services: unknown[] = [];

  ChosenService = -1;

  @Watch('customerId')
  async onCustomerIdChanged(newId: number) {
    if (this.customerId === -1) return;
    const CS = new CustomerService();
    this.Packages = (await CS.getBelongingPackages({
      id: this.customerId,
    })).data[0].packages_full;
  }

  @Watch('ChosenPackage')
  async onChosenPackageChanged(newPackage: number) {
    if (this.ChosenPackage === -1) return;
    const PSTS = new PSTService();
    this.Services = (await PSTS.getPackage({
      id: this.ChosenPackage,
    })).data.service_templates;
  }

  public async attemptAddToSession() {
    if (this.ChosenService === -1
    || this.ChosenPackage === -1
    || !this.ChosenService
    || !this.ChosenPackage
    || !this.customerId
    || this.customerId === -1) return;

    const SS = new SessionService();
    await SS.assignServiceToSession({
      sth_id: this.ChosenService,
      customer_id: this.customerId,
      package_id: this.ChosenPackage,
      day: parseInt(`${this.date.getDate()}`, 10),
      month: parseInt(`${this.date.getMonth() + 1}`, 10),
      year: parseInt(`${this.date.getFullYear()}`, 10),
    });

    this.$router.go(0);
  }

  public guardedClose(): void {
    if (!this.loading) {
      this.$emit('click:outside');
    }
  }

  public loading = false;
}
