




































































































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import CustomerService from '@/services/Customer.service';
import { Customer } from '@/types/customer-types';
import { AxiosResponse } from 'axios';
import Filters from '@/components/application/CustomerIndex/Filters.vue';
import { BarAction } from '@/components/application/UtilityBar/utility-bar-types';
import UtilityBar from '@/components/application/UtilityBar/UtilityBar.vue';
import StringSelect from '@/components/application/Filters/StringSelect.vue';
import DateIntSelect from '@/components/application/Filters/DateIntSelect.vue';
import StringSearch from '@/components/application/Filters/StringSearch.vue';
import LocationService from '@/services/Location.service';
import { Location } from '@/types/location-types';
import UserService from '@/services/User.service';
import PSTService from '@/services/PST.service';
import DisplayBox from '@/components/application/Filters/DisplayBox.vue';
import ToggleFilter from '@/components/application/Filters/Toggle.vue';

@Component({
  components: {
    ToggleFilter,
    DisplayBox,
    UtilityBar,
    StringSelect,
    DateIntSelect,
    StringSearch,
  },
})
export default class Index extends Vue {
  public barChunks: BarAction[] = [
    {
      icon: 'mdi-account-plus',
      text: 'ADD CUSTOMER',
      eventName: 'new-customer',
    },
  ];

  public goToNewCustomer(): void {
    this.$router.push({ name: 'New Customer' });
  }

  async goToProfile(c: Customer) {
    await this.$router.push({ name: 'Customer Profile', params: { id: c!.id!.toString() } });
  }

  SearchField = '';

  TotalPages = 0;

  LoadingResults = false;

  CurrentPage = 0;

  TableData: Customer[] = [];

  SortOrder = 'descending';

  async searchAndPaginate(e: string) {
    this.TotalDue = 'Calculating...';
    const CService = new CustomerService();
    let response: AxiosResponse;
    this.TableData = [];
    try {
      this.LoadingResults = true;
      response = await CService.searchAndPaginate({
        search: e,
        filters: this.Filters,
        order: this.SortOrder,
      });
      const CustomerData = response.data.data;
      this.TotalPages = response.data.last_page;
      this.CurrentPage = 1;
      this.TableData = CustomerData.map((customer: any) => {
        const tCustomer = new Customer();
        tCustomer.fromRaw(customer);
        return tCustomer;
      });
      this.LoadingResults = false;

      response = await CService.searchAndPaginate({
        search: e,
        filters: this.Filters,
        order: this.SortOrder,
        filtered_sum: 1,
      });
      this.TotalDue = response.data.payment_due_total;
    } catch (error) {
      console.log(error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public parseDate(input: string): string {
    const d = new Date(input);
    return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
  }

  Filters: Array<{
    field: string;
    operator: string;
    operand: string;
  }> = [];

  public async setFilters(filters: Array<{
    field: string;
    operator: string;
    operand: string;
  }>, requestless = false) {
    filters.forEach((filter) => {
      this.Filters = this.Filters.filter((e) => e.field !== filter.field);
    });
    this.Filters = this.Filters.concat(filters);
    if (!requestless) {
      await this.searchAndPaginate('');
    }
  }

  public async unsetFilters(filters: string[], requestless = false) {
    filters.forEach((filter) => {
      this.Filters = this.Filters.filter((e) => e.field !== filter);
    });
    if (!requestless) {
      await this.searchAndPaginate('');
    }
  }

  LocationVariants: string[] = [];

  OfficerVariants: string[] = [];

  ServiceVariants: Array<{id: number; name: string;}> = [];

  CollaboratorVariants: Array<{id: number; name: string;}> = [];

  TotalDue: number | string = 'Calculating...';

  async mounted() {
    const LS = new LocationService();
    const US = new UserService();
    const PSTS = new PSTService();

    const responses = await Promise.all([
      this.searchAndPaginate(''),
      LS.getAll(),
      US.getAll(),
      PSTS.getAllServices(),
      PSTS.getAllCollaborators(),
    ]);

    this.LocationVariants = responses[1].data.map((location: Location) => location.name);
    this.OfficerVariants = responses[2].data.map((officer: any) => officer.name);
    this.ServiceVariants = responses[3].data.map((sv: any) => ({ id: sv.id, name: sv.name }));
    this.CollaboratorVariants = responses[4].data.map((c: any) => ({ id: c.id, name: c.name }));
  }

  async moveToPage(e: string, f?: string[]) {
    const CService = new CustomerService();
    let response: AxiosResponse;
    this.TableData = [];
    try {
      this.LoadingResults = true;
      response = await CService.searchAndPaginate(
        {
          search: this.SearchField,
          page: parseInt(e, 10),
          filters: this.Filters,
          order: this.SortOrder,
        },
      );
      this.TotalDue = response.data.payment_due_total;
      this.LoadingResults = false;
      const CustomerData = response.data.data;
      this.CurrentPage = parseInt(e, 10);
      CustomerData.forEach((customer: any) => {
        const tCustomer = new Customer();
        tCustomer.fromRaw(customer);
        this.TableData.push(tCustomer);
      });
    } catch (error) {
      console.log(error);
    }
  }
}
