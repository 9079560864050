/* eslint-disable implicit-arrow-linebreak, camelcase */
import HttpClient from '@/services/Api.service';

interface GetWalletBody {
  wallet_id: number;
  wallet_type: number;
  filters?: any[];
  page?: number;
  order?: string;
  mode?: 'estimated' | 'real';
}

interface GetTransactionsBody {
  wallet_id: number;
  wallet_type: number;
}

interface MakeTransactionBody {
  sender: {
    type: number;
    id: number;
  };
  recipient: {
    type: number;
    id: number;
  }
  amount: number;
  payment_method: number;
  text: string;
}

interface PaginateTransactions {
  search?: string;
  page?: number;
  filters?: any[];
  order?: string;
  mode?: 'estimated' | 'real';
}

interface GetBucketParams {
  id: number;
  sth_id: number;
  filters?: any[];
}

interface GetAuditParams {
  month: number;
  year: number;
}

interface DeleteTransactionBody {
  id: number;
}

class TransactionService extends HttpClient {
  public constructor() {
    super((process.env.VUE_APP_BASE_URL)!);
  }

  public getWallet = (params: GetWalletBody) =>
    this.instance.get('/get-wallet', { params });

  // TODO: Unused here to match usage in Wallet.vue
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getGlobalWallet = (params: GetWalletBody) =>
    this.instance.get('/get-global-wallet');

  public getTransactions = (params: GetTransactionsBody) =>
    this.instance.get('/get-transactions', { params });

  public getAllTransactions = () =>
    this.instance.get('/get-all-transactions');

  public makeTransaction = (mtb: MakeTransactionBody) =>
    this.instance.post('/make-transaction', JSON.stringify(mtb));

  public paginateAll = (params: PaginateTransactions) =>
    this.instance.get('/paginate-transactions', { params });

  public getAllBuckets = () =>
    this.instance.get('/get-all-buckets');

  public getSingleBucket = (params: GetBucketParams) =>
    this.instance.get('/get-single-bucket', { params });

  public getAudit = (params: GetAuditParams) =>
    this.instance.get('/get-audit', { params });

  public deleteTransaction = (dtb: DeleteTransactionBody) =>
    this.instance.post('/delete-transaction', JSON.stringify(dtb));
}

export default TransactionService;
