



















































































































































































































































































/* eslint-disable camelcase */
import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { BarAction } from '@/components/application/UtilityBar/utility-bar-types';
import UtilityBar from '@/components/application/UtilityBar/UtilityBar.vue';
import Loading from '@/components/application/Loading.vue';
import TransactionService from '@/services/Transaction.service';
import UserService from '@/services/User.service';
import { PAYMENT, TRADER } from '@/types/transaction-enums';
import DateIntSelect from '@/components/application/Filters/DateIntSelect.vue';

@Component({
  components: {
    UtilityBar,
    Loading,
    DateIntSelect,
  },
})
export default class BucketsIndex extends Vue {
  public barChunks: BarAction[] = [
    {
      icon: 'mdi-arrow-left',
      text: 'BACK TO FINANCE',
      eventName: 'go-to-finance',
    },
  ];

  public buckets: any[] = [];

  public transactionAmount = 0;

  public transactionNote = '';

  public loading = false;

  public perm = this.$store.state.User.Permissions;

  public bMonth: number | null = null;

  public setFilters(filters: Array<{
    field: string;
    operator: string;
    operand: string;
    month: number;
  }>): void {
    filters.forEach((filter) => {
      this.Filters = this.Filters.filter((e) => e.field !== filter.field);
    });
    this.Filters = this.Filters.concat(filters);
    if (typeof this?.selectedBucket?.index !== 'undefined') {
      this.selectBucket(this.selectedBucket.index);
      this.bMonth = filters[0].month;
    }
  }

  public unsetFilters(filters: string[]): void {
    filters.forEach((filter) => {
      this.Filters = this.Filters.filter((e) => e.field !== filter);
    });
    if (typeof this?.selectedBucket?.index !== 'undefined') {
      this.selectBucket(this.selectedBucket.index);
      this.bMonth = null;
    }
  }

  public async makeTransaction(): Promise<void> {
    if (this.selectedBucket === null) return;
    if (this.transactionAmount > 0
      && this.transactionAmount <= this.selectedBucket.amount
      && this.selectedUser.id !== -1
      && !Number.isNaN(this.transactionAmount)
      && !Number.isNaN(this.selectedBucket.amount)
      && !this.loading) {
      this.loading = true;
      const TService = new TransactionService();
      const transactionBody: any = {
        sender: {
          type: TRADER.BUCKET,
          id: this.buckets[this.selectedBucket.index].id,
        },
        recipient: {
          type: TRADER.OFFICER,
          id: this.selectedUser.id,
        },
        amount: this.transactionAmount,
        payment_method: PAYMENT.CASH,
        text: this.transactionNote || 'No note specified',
      };
      await TService.makeTransaction(transactionBody);
      this.loading = false;
      this.transactionAmount = 0;
      this.transactionNote = '';
      this.selectedUser = {
        id: -1,
        name: 'Choose an Officer',
      };
      await this.selectBucket(this.selectedBucket?.index);
    }
  }

  get conditions(): boolean {
    if (this.selectedBucket === null) return false;
    return (this.transactionAmount > 0
      && this.transactionAmount <= this.selectedBucket.amount
      && this.selectedUser.id !== -1
      && !Number.isNaN(this.transactionAmount)
      && !Number.isNaN(this.selectedBucket.amount));
  }

  public selectedUser: {id: number; name: string;} = {
    id: -1,
    name: 'Choose an Officer',
  }

  public selectedBucket: {
    amount: number;
    service_income: number;
    service_expenses: number;
    name: string;
    index: number;
  } | null = null;

  Filters: Array<{
    field: string;
    operator: string;
    operand: string;
  }> = [];

  public async selectBucket(i: number) {
    const TS = new TransactionService();
    const response = await TS.getSingleBucket({
      id: this.buckets[i].id,
      sth_id: this.buckets[i].sth_id,
      filters: this.Filters,
    });

    this.selectedBucket = {
      amount: parseFloat(response.data.amount),
      service_income: parseFloat(response.data.service_income),
      service_expenses: parseFloat(response.data.service_expenses),
      name: this.buckets[i].bucket_name,
      index: i,
    };
  }

  public users: any[] = [];

  public async mounted() {
    const TS = new TransactionService();
    const US = new UserService();
    const responses = await Promise.all([
      TS.getAllBuckets(),
      US.getAll(),
    ]);

    this.users = [...responses[1].data];

    const expensesAccount = this.users.find((acc) => acc.name.includes('Expenses'));

    if (expensesAccount) {
      this.selectedUser = {
        id: expensesAccount.id,
        name: expensesAccount.name,
      };
    }

    this.buckets = [...responses[0].data];
  }

  public async goToFinance() {
    await this.$router.go(-1);
  }
}
