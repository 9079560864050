



















































































































































































































































import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { BarAction } from '@/components/application/UtilityBar/utility-bar-types';
import Loading from '@/components/application/Loading.vue';
import TransactionService from '@/services/Transaction.service';
import UtilityBar from '@/components/application/UtilityBar/UtilityBar.vue';
import { AxiosPromise } from 'axios';

interface SingularAuditData {
  [K: string]: {
    [L: string]: [number, number, number]
  };
}

interface AuditData {
  officers: SingularAuditData;
  offices: SingularAuditData;
  services: SingularAuditData;
}

@Component({
  components: {
    UtilityBar,
  },
})
export default class Audit extends Vue {
  public barChunks: BarAction[] = [
    // {
    //   icon: 'mdi-send',
    //   text: 'SEND MONEY',
    //   eventName: 'send-money-start',
    // },
    // {
    //   icon: 'mdi-download-multiple',
    //   text: 'ADD MONEY',
    //   eventName: 'add-money-start',
    // },
  ];

  public picker: string = new Date().toISOString().substr(0, 7);

  public auditData: AuditData | null = null;

  // eslint-disable-next-line class-methods-use-this
  public getIEPLabels(length: number, withTotal = false) {
    const result: string[] = [];
    /* eslint-disable no-restricted-syntax */
    for (const i of Array(length).keys()) {
      result.push('INCOME');
      result.push('EXPENSES');
      result.push('PROFIT');
    }
    if (withTotal) {
      result.push('INCOME');
      result.push('EXPENSES');
      result.push('PROFIT');
    }
    return result;
  }

  // eslint-disable-next-line class-methods-use-this
  public getWeavedValues(target: any) {
    const result: string[][] = [];
    const randomTarget = Object.values(target)[0];
    let resultI = 0;

    for (const [key, value] of Object.entries(randomTarget as any)) {
      result.push([]);
      result[resultI].push(key);
      for (const targetpiece of Object.values(target)) {
        result[resultI].push((targetpiece as any)[key][0]);
        result[resultI].push((targetpiece as any)[key][1]);
        result[resultI].push((targetpiece as any)[key][2]);
      }
      result[resultI].push(
        result[resultI]
          .slice(1, result[resultI].length)
          .filter((el, index) => index % 3 === 0)
          .reduce((acc, curr) => acc + curr),
        result[resultI]
          .slice(1, result[resultI].length)
          .filter((el, index) => index % 3 === 1)
          .reduce((acc, curr) => acc + curr),
        result[resultI]
          .slice(1, result[resultI].length)
          .filter((el, index) => index % 3 === 2)
          .reduce((acc, curr) => acc + curr),
      );
      resultI += 1;
    }
    result.push(
      (new Array(result[resultI - 1].length))
        .fill('0')
        .map((elem, index) => {
          let e = parseInt(elem, 10);
          result.forEach((rel) => {
            e += parseInt(rel[index], 10);
          });
          return `${e}`;
        }),
    );
    result[resultI][0] = 'Monthly Totals';
    console.log(result);
    return result;
  }

  async getAudit() {
    const TS = new TransactionService();
    // eslint-disable-next-line prefer-const
    let [year, month] = this.picker.split('-').map((str) => parseInt(str, 10));
    month -= 1;
    this.auditData = (await TS.getAudit({
      month,
      year,
    })).data;
  }

  async mounted() {
    this.getAudit();
  }
}
