















































































import 'reflect-metadata';
// eslint-disable-next-line object-curly-newline
import { Vue, Component, Prop } from 'vue-property-decorator';
import CustomerService from '@/services/Customer.service';
import { AxiosResponse } from 'axios';

/* eslint-disable camelcase */
export interface Note {
  id: number,
  customer_id: number,
  note: string,
  created_at: string,
  updated_at: string,
  user_name: string,
}
/* eslint-enable camelcase */

@Component
export default class NotesDialog extends Vue {
  @Prop() readonly model!: boolean;

  @Prop() readonly notes!: Note[];

  public guardedClose(): void {
    if (!this.loading) {
      this.$emit('click:outside');
    }
  }

  public async createNote(): Promise<any> {
    this.sendLoading = true;
    const C = new CustomerService();
    try {
      await C.createNote({
        customer_id: parseInt(this.$route.params.id, 10),
        note: this.noteText,
      });
      this.$emit('request-update');
      this.noteText = '';
      this.expand = false;
    } catch (e) {
      console.log(e);
    }
    this.sendLoading = false;
  }

  public loading = false;

  public sendLoading = false;

  public expand = false;

  public noteText = '';

  // eslint-disable-next-line class-methods-use-this
  public noteDateFormat(d: Date): string {
    const hours = `${d.getHours() < 10 ? '0' : ''}${d.getHours()}`;
    const minutes = `${d.getMinutes() < 10 ? '0' : ''}${d.getMinutes()}`;
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()} ${hours}:${minutes}`;
  }
}
